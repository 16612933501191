@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.checkout-shipping {
  .MuiCard-root {
    display: flex;
    flex-direction: row;

    border-radius: 0px;
    border: 0.5px solid #979797;
  }
  .shipping-card-left {
    width: 68%;
    padding-right: 24px;
    border-right: 1px solid $light-gray;
    text-align: left;

    @include small {
      width: 100%;
      border-right: none;
      padding-right: 0;
    }
  }

  .shipping-card-right {
    width: 30%;

    @include small {
      margin-top: 20px;
      width: 100%;
      padding-left: 0;
      text-align: -webkit-center;
      align-self: auto;
    }

    table {
      border: none;
    }
  }
  .MuiTableCell-root {
    vertical-align: top !important;
  }

  .MuiTableCell-head {
    font-family: "Nunito Sans", sans-serif !important;
  }

  .MuiInputLabel-outlined,
  .MuiTableCell-root {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px;
    font-family: "Nunito Sans", sans-serif !important;
  }
  .MuiTableCell-root {
    font-family: "Nunito Sans", sans-serif !important;
  }

  .checkout-button-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @include small {
      width: 100%;
    }
  }

  table .MuiTableCell-root {
    font-family: "Nunito Sans", sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .checkout__breadcrumbs {
    text-transform: uppercase;
    font-family: "Nunito Sans", sans-serif !important;
  }

  .checkout__actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;
  }

  .StripeElement {
    padding: 19px;
  }

  .StripeElement {
    border-color: rgba(0, 0, 0, 0.23);
  }

  .coupon-row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .MuiTextField-root {
      flex-grow: 1;
    }
    .text--error {
      font-size: 10px;
    }

    .MuiButtonBase-root {
      width: auto;
      font-size: 10px;
      color: $gray;
      font-size: 10px;
      height: 20px;
      font-weight: 400;
      padding: 0px;

      &.has-code {
        text-decoration: underline;
        font-weight: bold;
      }

      .MuiCircularProgress-root {
        height: 10px !important;
        width: 10px !important;
      }

      span {
        letter-spacing: 2px;
      }
      &.Mui-disabled {
        background: transparent !important;
      }
    }
  }
  .MuiButtonBase-root.Mui-disabled {
    //border: none !important;
    //background: transparent !important;
  }

  .checkout__breadcrumbs {
    .MuiSvgIcon-root {
      font-size: 12px;
      margin-bottom: 0.5px;
    }

    p {
      font-weight: 400;
      font-size: 8px;
      text-transform: uppercase;
      letter-spacing: 3.2px;
    }

    p.back {
      cursor: pointer;
    }

    p.back:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}
