@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.container {
  display: flex;
  flex-direction: column;
  max-width: 520px;

  .title {
    font-family: $georgia;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    color: $gray;
    margin: 0;
    margin-bottom: 8px;
  }

  .description {
    font-family: $nunito;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.1px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    margin-bottom: 24px;
  }

  .actions {
    display: grid;
    grid-template-columns: 207px 207px;
    gap: 16px;
    align-items: center;
    justify-content: center;

    .card {
      display: grid;
      grid-template-rows: 38px 24px 78px 36px;
      gap: 8px;
      max-height: 224px;
      border: 1px solid #0000001f;
      padding: 10px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        border: 1px solid $blue;

        .cardButton {
          background-color: $peach !important;
          color: $white !important;
        }
      }

      .cardTitle {
        font-family: $nunito;
        font-weight: 700;
        font-size: 14px;
        line-height: 19.1px;
        letter-spacing: 1px;
        text-align: center;
        color: $blue;
        margin: 0;
      }

      .cardDescription {
        font-family: $nunito;
        font-weight: 400;
        font-style: italic;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: center;
        color: $gray;
        margin: 0;
      }
    }
  }
}

.rightImages {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: center;

  .jar {
    position: relative;
    .jarSquare {
      position: absolute;
      background-color: $peach;
      width: 14px;
      height: 14px;
      z-index: 2;
      left: 14px;
      bottom: 21px;
    }
  }

  .lipStick {
    position: relative;

    .lipStickSquare {
      position: absolute;
      background-color: $peach;
      width: 5px;
      height: 8.4px;
      z-index: 2;
      left: 6px;
      bottom: 13px;
    }
  }

  .circle {
    position: relative;

    .circleSquare {
      position: absolute;
      background-color: $peach;
      width: 10px;
      height: 10px;
      z-index: 2;
      left: 11px;
      bottom: 16px;
    }
  }

  .squares {
    position: relative;

    .squaresSquare {
      position: absolute;
      background-color: $peach;
      width: 7px;
      height: 7px;
      z-index: 2;
      left: 11px;
      top: 7px;
    }
  }

  .squaresSquareTwo {
    position: absolute;
    background-color: $peach;
    width: 10px;
    height: 10px;
    z-index: 2;
    left: 10px;
    bottom: 16px;
  }
}
