@import "styles/colors.scss";
@import "styles/mixins";

.signUpFormContainer {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  position: relative;

  @include small {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }

  .form {
    display: flex;
    padding-top: 10px;
    flex-wrap: wrap;
    justify-content: center;

    @include small {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .termsConditionsContainer {
    display: flex;
    padding-left: 10px;
    padding-top: 0.5rem;

    .termsText {
      font-size: 10px;
      color: $gray;
      padding-top: 2px;
      margin: 0;
    }

    @include small {
      flex-direction: column;
      align-items: center;
      margin-top: 42px;
      padding: 0 10px;
      text-align: center;
    }
  }

  .recaptchaContainer {
    display: flex;
    padding-left: 10px;
    padding-bottom: 0.5rem;

    .recaptchaText {
      font-size: 10px;
      color: $gray;
      padding-top: 0.25rem;
      margin: 0;
    }

    @include small {
      flex-direction: column;
      align-items: center;
      margin: 16px 0;
      padding: 0 10px;
      text-align: center;
    }
  }

  .actionContainer {
    display: flex;
    flex-direction: column;

    // width: 100%;
    margin: 10px !important;
    padding-bottom: 0px;
    @include small {
      flex-direction: column;
      // width: 97%;
      margin: 0px !important;
    }
  }

  .fullNameContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;

    @include small {
      flex-direction: row;
      width: 100%;
    }
  }

  .fieldWidth {
    width: 48.5%;
    @include small {
      margin: 0 auto;
      width: auto;
    }
  }

  .emailPassContainer {
    width: 450px;
    margin-bottom: 0px;
    @include small {
      width: 97%;
    }
  }

  .mobileInputForm {
    display: flex;
    flex-direction: column;
    width: 450px;
    margin-bottom: 15px;

    @include small {
      width: 97%;
    }
  }

  .divider {
    color: #ccc;
    margin-bottom: 42px;
    width: 100%;
    height: fit-content;
  }
}

.textFieldWidth {
  width: 410px;
  margin: 10px !important;
  margin-left: 0px !important;
  @include small {
    width: 100%;
    margin: 15px 0px !important;
  }
}

.textError {
  margin: 0 auto;
  //position: absolute;
  margin-top: 15px;
  left: 0px;
  bottom: 0px;
}

.submitButton {
  margin: 0 auto;
}

.marginTen {
  // /*margin: 10px !important;
  // width: 100%;
  // @include small{
  //   width: 100%;
  //   margin: 15px 0px !important;
  // }*/
}

.padding {
  padding-left: 5px;
  @include small {
    width: 100%;
    padding-left: 0px;
    margin-top: 25px;
  }
}

.passwordStrength {
  li {
    font-size: 10px;
    color: $error;
  }
}

.passwordStrengthBar {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;

  p {
    top: initial;
    bottom: -20px;
  }
}

.ssoContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  max-width: 450px;
  width: 100%;
  margin-bottom: 44px;
  margin: 0 auto;

  @include small {
    width: 97%;
  }

  .appleSsoWrapper {
    width: 100%;
    padding: 5px;

    &:hover {
      cursor: pointer;
      transition:
        background-color 0.218s ease 0s,
        border-color 0.218s ease 0s,
        box-shadow 0.218s ease 0s;
      box-shadow: rgb(66 133 244 / 30%) 0px 0px 3px 3px;
    }
  }

  .ssoBtn {
    background: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.54);
    height: 52px;
    width: 210px;
    border: 0.5px solid rgb(204, 204, 204);
    text-align: center;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    border-radius: 4px;
    font-family: "Nunito Sans";
    user-select: none;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px 0px 0px;
    gap: 4px;
    flex: 0 0 auto;
    order: 1;
    font-style: normal;
    font-weight: 400;

    &:hover {
      cursor: pointer;
      transition:
        background-color 0.218s ease 0s,
        border-color 0.218s ease 0s,
        box-shadow 0.218s ease 0s;
      box-shadow: rgb(66 133 244 / 30%) 0px 0px 3px 3px;
    }
  }
}

@include small {
  .form {
    align-items: flex-start;
    // padding-left: 2%;
  }

  .signUpFormContainer {
    .ssoContainer {
      flex-direction: column;
      align-items: center;
    }

    .ssoBtn {
      width: 100%;
    }

    .divider {
      max-width: 560px;
      width: 100%;
      margin: 0 auto 42px auto;
      justify-content: center;
    }
  }
}

.mobileTermsWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
