@import "styles/mixins";
@import "styles/text";
@import "styles/colors";

.productCard {
  position: relative;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;

  // need this in order to have aspect ratio proper with current figma designs but dynamic width
  padding-bottom: 118%;
  border: 1px solid transparent;

  transition: border-color 0.3s ease;

  @include small {
    padding-bottom: 160%;
  }

  &:hover {
    border-color: #d56f5b;
  }

  .productVariantsSide {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: flex-end;
    z-index: 2;
    right: 12px;
    top: 12px;

    transition: right 0.3s ease;

    .variantSidePlus {
      font-family: $nunito;
      font-size: 10px;
      font-weight: 600;
      line-height: 13.64px;
      text-align: center;
      color: $medium-gray;
      position: relative;
    }

    .variantCircleSide {
      height: 10px;
      width: 10px;
      min-height: 10px;
      min-width: 10px;
      max-height: 10px;
      max-width: 10px;
      border-radius: 50%;
      position: relative;
      border: 1px solid #fff;
      position: relative;
      cursor: pointer;

      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.5);
      }
    }
  }

  .productImages {
  }

  .productContent {
    min-height: 68px;
    padding: 8px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: white;

    @include small {
      min-height: fit-content;
    }

    transition: height 1 ease;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 0px 16px -6px #00000040;
      height: fit-content;
    }

    .productVariants {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 0;
      cursor: pointer;
      flex-wrap: wrap;
      position: relative;
      display: none;
      margin-bottom: 0.5rem;

      &.visible {
        display: flex;
      }

      .variantsPlus {
        font-family: $nunito;
        font-size: 14px;
        font-weight: 700;
        line-height: 19.1px;
        text-align: center;
        color: $medium-gray;
        position: relative;
        left: 2px;
      }

      .noColorIconBig {
        min-height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
        border-radius: 16px;
        position: relative;

        &:hover {
          outline: 3px solid rgba(0, 0, 0, 0.12); // Use an outline with opacity instead of border
        }
      }

      .variantCircle {
        min-height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
        border-radius: 16px;
        position: relative;

        &:hover {
          outline: 3px solid rgba(0, 0, 0, 0.12); // Use an outline with opacity instead of border
        }

        .variantSelected {
          position: absolute;
          top: -10px;
          right: -3px;
        }
      }
    }

    .productName {
      color: $gray;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: $nunito;
    }

    .productFooter {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 8px;

      .productFooterPrice {
        display: flex;
        flex-direction: column;

        @include small {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 8px;
        }
      }

      .productFooterActions {
        display: flex;
        gap: 0.5rem;
        margin-top: 0.25rem;

        opacity: 0;
        position: relative;
        transition: opacity 0.3s ease-in-out; // Add transition for opacity

        &.visible {
          opacity: 1; // Fully visible when the class is active
        }
      }
    }
  }
}

.productMobilePrices {
  display: none;
  @include small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
}

.productSrp {
  background-color: #2460711a;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  padding-top: 2px;
}

.productProfit {
  background-color: #d56f5b;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  padding-top: 0;
}

.priceLabel {
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 1px;
  color: #d56f5b;
  font-family: $nunito;
  text-transform: uppercase;
  height: 11px;

  @include small {
    font-size: 8px;
    height: 8px;
    line-height: 8px;
  }
}

.priceContainer {
  height: 14px;
  @include small {
    height: auto;
  }
}

.priceDetailsContainer {
  display: flex;
  align-items: center;
  padding: 2px 0;
}

.dollarSign {
  font-size: 10px;
  color: #d56f5b;
  font-weight: 600;
  position: relative;
  top: -2px;
  font-family: $nunito;
}

.price {
  font-size: 14px;
  color: #d56f5b;
  font-weight: 700;
  line-height: 11px;
  font-family: $nunito;

  @include small {
    font-size: 12px;
  }
}

.vipContainer {
  position: absolute;
  z-index: 3;
  min-height: 16px;
  max-height: 16px;
  border-radius: 100px;
  padding: 3px 6px;

  margin: auto;
  left: 0;
  right: 0;
  top: -18px;
  width: fit-content;

  .vipBadge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.productStatusContainer {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.premiumTag {
  font-family: $nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 13.64px;
  text-align: center;
  color: $medium-gray;

  border: 1px solid #979797;
  padding: 1px 4px;
  border-radius: 100px;

  margin-bottom: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
