@import "styles/colors.scss";
@import "styles/mixins.scss";

:global(.MuiModal-root) {
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 90%;
  margin: auto;
  max-width: 95%;
}

.modalWrapper {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 40px;
  max-width: 1200px;
  max-height: 90%;

  @include small {
    padding: 20px;
    max-height: 100%;
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .text {
    &Header {
      padding-top: 40px;
      color: $medium-gray;
    }
  }

  &:focus {
    outline: none;
  }
}

.modalCloseButton {
  position: absolute;
  text-transform: uppercase;
  color: inherit;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 2.4px;
  cursor: pointer;

  top: 10px;
  right: 10px;

  &:hover {
    color: $peach;
  }
}

@include small {
  :global(.MuiModal-root) {
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    max-width: 100%;
    max-height: 100%;
  }

  .modalWrapper {
    margin: auto;
    padding: 40px 20px;

    // max-width: 100%;
    // max-height: 100%;

    width: fit-content;
    height: fit-content;

    // width: 345px;
    // height: 405px;
    // margin: 0;
    // width: 100%;
    // margin: auto;
  }

  .modalCloseButtonProduct {
    position: absolute;
    text-transform: uppercase;
    color: #5e5e5e;
    font-size: 8px;
    font-weight: 100;
    letter-spacing: 2.4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    top: 25px;
    right: 0px;
    left: 15px;

    height: 60px;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #5e5e5e;
  }

  .modalCloseButton {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    top: 30px;
  }

  .productDetailHeader {
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    span {
      text-align: center;
    }

    .placeIcon {
      position: absolute;
      left: 10px;
    }
  }

  .filterMenuBoxShadow {
    box-shadow: 0px 4px 4.7px rgba(0, 0, 0, 0.1);
    left: 0;
    top: 0;
  }

  .title {
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 5.2px;
    text-transform: uppercase;
  }
}
