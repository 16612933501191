@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.settingsView {
  .title {
    font-family: $georgia !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32.4px;
    color: $gray;
    margin-bottom: 24px;
  }

  .innerCard {
    border: 1px solid #0000001f;
    background-color: $white;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 32px;

    @include small {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .subtitle {
      font-family: $georgia !important;
      font-size: 21px;
      font-weight: 700;
      line-height: 29.4px;
      text-align: left;
    }

    .description {
      font-family: $nunito !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.37px;
      text-align: left;
      color: $gray;
    }

    .settingTitle {
      font-family: $nunito !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.1px;
      text-align: left;
      color: $gray;
    }

    .settingColumn {
      font-family: $nunito !important;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 2px;
      color: $gray;
    }

    .editStock {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $peach;
      }
    }

    .editRow {
      display: inline-flex;
      align-items: center;
      gap: 8px;

      .editStockInput {
        width: 40px;
        border: 1px solid $medium-gray;
        text-align: center;
        padding: 4px;

        font-family: $nunito !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.37px;
        text-align: center;
        color: $gray;

        &:focus {
          outline: none;
        }
      }
    }

    .notificationsList,
    .orderPaymentsList {
      display: flex;
      flex-direction: column;

      .notificationsListRow {
        padding: 12.5px 0;
        display: grid;
        grid-template-columns: 1fr 0.3fr 0.3fr;
        border-bottom: 1px solid #0000001f;
        align-items: center;
        gap: 1rem;

        @include small {
        }
      }

      .orderPaymentsListRow {
        padding: 12.5px 0;
        display: grid;
        grid-template-columns: 1fr 0.23fr;
        border-bottom: 1px solid #0000001f;
        align-items: center;
        gap: 2rem;

        @include small {
        }
      }

      .noBorder {
        border: none;
      }
    }
  }

  .separator {
    margin-top: 24px;
  }
}

.orderPaymentsList {
  display: flex;
  flex-direction: column;
  width: 100%;

  .orderPaymentsListRow,
  .shippingListRow {
    padding: 12.5px 0;
    display: grid;
    grid-template-columns: 1fr 200px;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #0000001f;

    &:last-child {
      border-bottom: none;
    }
  }

  .shippingListRow {
    @include small {
      display: flex;
    }
  }

  .settingTitle {
    font-family: $nunito;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .description {
    font-family: $nunito;
    font-size: 12px;
    color: $gray;
    margin: 0;
  }
}

.dropdownFormControl {
  position: relative;
  align-self: flex-end;
  // width: 180px;

  @include small {
    width: 100%;
  }

  .dropdown {
    border: 1px solid #ccc;
    border-radius: 4px;

    height: auto;

    * > div {
      padding: 7.5px 0 7.5px 10px;
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      gap: 4px;
    }

    @include small {
      padding: 0;
    }
  }
}
