@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.tasksCenter {
  position: relative;
  top: 2px;
  display: inline-block;

  @include small {
    top: -2px;
  }

  .actionWrapper {
    position: relative;
    .cta {
      position: relative;
      padding: 4px 8px !important;
      padding-right: 24px !important;
      border-color: $gray !important;
      color: $gray !important;
      height: 22px;

      font-family: $nunito;
      font-size: 9px;
      font-weight: 600;
      line-height: 12.28px;
      letter-spacing: 0.2em;
      text-align: center;

      &:hover {
        background-color: $medium-gray !important;
        color: $white !important;
      }

      @include small {
        top: -2px;
        // border: none;
      }

      svg {
        position: absolute;
        top: 2px;
        right: 8px;
        font-size: 16px;
      }
    }
  }

  .popoverContent {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    box-shadow: 10px 10px 40px 0px #00000029;
    z-index: 10;
    right: 0;
    min-width: 358px;
    max-width: 358px;
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    z-index: 9999;

    @include small {
      position: fixed;
      top: 56px !important;
      left: 0;
      right: 0;
      min-width: 95%;
      max-width: 95%;
      margin: auto;
      transform: initial;
    }

    .popoverBody {
      text-wrap: initial !important;
      text-align: left;
      position: relative !important;
    }

    .popoverBody {
      padding: 24px;
      padding-bottom: 8px;
      padding-right: 12px;
      border: 1px solid #cccccc;
      border-radius: 4px;
      background-color: #fff;
      font-size: 12px;
      color: $gray;
      text-wrap: nowrap;
      position: relative !important;

      display: flex;
      flex-direction: column;

      .closeWrapper {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
        }
      }

      .title {
        font-family: $georgia;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $gray;
        margin-bottom: 8px;
        cursor: pointer;
        display: inline-block;
        width: fit-content;

        &:hover {
          color: $peach;
        }
      }

      .subtitle {
        font-family: $nunito;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.01px;
        text-align: left;
        color: $gray;
        margin-bottom: 20px;
      }

      .tasksStepper {
        .stepperIconContainerDone {
          svg {
            color: $blue !important;
          }
        }

        .stepperlabelRoot {
          padding: 0 !important;

          .title {
            font-family: $nunito;
            font-size: 14px;
            font-weight: 700;
            line-height: 19.1px;
            text-align: left;
            color: $peach;
          }
        }

        .stepperlabelRootDone {
          pointer-events: none;
          color: $light-gray !important;

          .title {
            color: $light-gray !important;
          }
        }

        .stepperContentRoot {
          border-color: $peach !important;
          padding-bottom: 16px;

          .content {
            display: grid;
            grid-template-columns: 1fr 24px;
            gap: 6px;

            .icon {
              opacity: 0.4;
              top: -20px;
              position: relative;
            }

            p {
              margin: 0;
              margin-bottom: 8px;
              &:last-child {
                margin-bottom: 0;
              }
            }

            a {
              &:hover {
                color: $peach;
                cursor: pointer;
              }
            }
          }
        }

        .stepperContentRootDone {
          pointer-events: none;

          border-color: $blue !important;

          p,
          a {
            color: $light-gray !important;
          }

          .icon {
            opacity: 0.4;
            top: -20px;
            position: relative;
            color: $blue !important;
          }
        }
      }
    }
  }
}
