@import "styles/mixins";
@import "styles/colors";

.productCard {
  position: relative;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding-bottom: 118%; // Keeps the same aspect ratio as the actual product card
  border: 1px solid transparent;

  @include small {
    padding-bottom: 160%;
  }

  .productVariantsSide {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 12px;
    top: 12px;

    .variantCircleSkeletonSide {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #a3a3a3;
      margin-bottom: 4px;
      animation: pulse 3s ease-in-out infinite;
    }
  }

  .productImages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 30%; // Keeps the same height as the product image container
    border-radius: 100%;
    .imageSkeleton {
      width: 100%;
      height: 100%;
      background: #a3a3a3;
      border-radius: 8px;
      animation: pulse 3s ease-in-out infinite;
    }
  }

  .productContent {
    min-height: 68px;
    padding: 8px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 5;

    .productVariants {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 8px 16px;

      .variantCircleSkeleton {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #a3a3a3;
        animation: pulse 3s ease-in-out infinite;
      }
    }

    .nameSkeleton {
      width: 70%;
      height: 16px;
      background-color: #a3a3a3;
      margin: 12px 0;
      animation: pulse 3s ease-in-out infinite;
    }

    .productFooter {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .priceSkeleton {
        width: 50px;
        height: 14px;
        background-color: #a3a3a3;
        animation: pulse 3s ease-in-out infinite;
      }

      .priceLabelSkeleton {
        width: 40px;
        height: 10px;
        background-color: #a3a3a3;
        animation: pulse 3s ease-in-out infinite;
        margin-top: 4px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
