@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

form {
  .Mui-error {
    margin: 0;
    margin-top: 8px;
  }

  .MuiInputLabel-root {
    top: -8px;
    left: -15px;
    font-size: 14px !important;
    text-transform: uppercase;
    letter-spacing: 2.00094px;
    font-family: "Nunito Sans", sans-serif !important;
  }

  .MuiOutlinedInput-input {
    border: 1px solid $gray;
    padding: 16.5px 14px !important;
  }

  .MuiOutlinedInput-input.Mui-disabled {
    cursor: not-allowed;
    border: 1px solid #5e5e5e80 !important;
    background-color: #cccccc33 !important;
    color: $medium-gray !important;
    -webkit-text-fill-color: $medium-gray !important;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .Mui-focused input {
    border: none;
    border: 1px solid #d46e5a;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 0px;
  }

  input {
    font-family: "Nunito Sans", sans-serif !important;
  }
}

.MuiInputLabel-formControl {
  color: $gray !important;
}

.MuiAutocomplete-inputRoot {
  padding: 0px !important;
  font-family: "Nunito Sans", sans-serif !important;

  @include small {
    width: inherit !important;
  }
}

.MuiCheckbox-root {
  padding: 0px !important;
  margin-right: 10px;
}

.MuiFormControlLabel-root {
  margin-left: 0px !important;
  .MuiFormControlLabel-label {
    text-transform: uppercase;
    letter-spacing: 1.6px;
    font-size: 8px;
    line-height: 15px;
    color: $gray;
    font-family: "Nunito Sans", sans-serif !important;
  }
}

.MuiLink-root {
  text-decoration-color: $gray !important;
  cursor: pointer;
}

#shipping-form {
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .doubleLine {
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    align-items: stretch;
    input,
    .MuiFormControl-root {
      width: auto;
      flex: 1 1 0px;
    }

    .MuiFormControl-root:first-of-type {
      margin-right: 10px;
    }
  }

  .tripleLine {
    margin-bottom: 25px;
    display: flex;
    align-items: stretch;
    width: 100%;
    input,
    select,
    .MuiFormControl-root {
      width: auto;

      flex: 1 1 0px;
    }

    .MuiFormControl-root:first-of-type {
      margin-right: 10px;
    }
    .MuiFormControl-root:nth-of-type(2) {
      margin-right: 10px;
    }
  }

  .singleLine {
    margin-bottom: 25px;
    width: 100%;
    input {
      width: 100%;
    }

    .MuiTextField-root {
      width: 100%;
    }
  }

  .button--red-outline {
    right: 0px;
    margin-top: 25px;
  }

  .MuiAutocomplete-root {
    width: 250px;

    @include small {
      width: 100% !important;
    }

    input,
    .MuiFormControl-root,
    .MuiOutlinedInput-root {
      width: 250px !important;

      @include small {
        width: 100% !important;
      }
    }
    .MuiInputLabel-root {
    }
  }
}

input {
  font-family: "Nunito Sans", sans-serif !important;
}
