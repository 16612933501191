@import "styles/colors";
@import "styles/mixins";
@import "styles/text.scss";

.automaticPaymentStatusWrapper {
  padding-right: 16px;

  @include small {
    width: 100%;
    padding-left: 16px;
  }

  .automaticPaymentStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 300px;
    height: 24px;
    gap: 8px;

    @include small {
      width: 100%;
    }

    border-radius: 100px;

    &.bgSuccess {
      background-color: rgba(36, 96, 113, 0.1);
    }

    &.bgError {
      background-color: $light-peach;
    }

    .text {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .error {
      color: $error;
    }

    .success {
      color: $blue;
    }

    img {
      width: 12px;
      height: 12px;
    }
  }

  .errorMessage {
    display: flex;
    align-items: center;
    width: 300px;
    gap: 8px;
    margin-top: 8px;

    @include small {
      width: 100%;
    }

    .errorText {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $error;
    }

    svg {
      align-self: flex-start;
      vertical-align: text-top;
      width: 14px;
      height: 14px;
      color: $error;
    }
  }
}
