@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.notificationCenter {
  position: relative;
  top: 2px;
  display: inline-block;

  @include small {
    top: 0px;
  }

  .iconWrapper {
    position: relative;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.2s ease-in-out;
    }

    .iconBadge {
      cursor: pointer;
      position: absolute;
      z-index: 4;
      top: -6px;
      right: -8px;
      width: 15px;
      height: 15px;
      border-radius: 100px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: $peach;
      box-shadow: 0px 0px 4px 0px #d56f5b80;
      border: 1px solid #ffdfd8;
      color: $white;

      font-family: $nunito;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.03em;
    }

    .iconBadgeBig {
      min-width: 21px;
      // padding: 0px 5px;
      height: 15px;
    }
  }

  .popoverContent {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    box-shadow: 10px 10px 40px 0px #00000029;
    z-index: 10;
    left: 99%;
    transform: translateX(-99%);
    min-width: 500px;
    max-width: 500px;
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    z-index: 9999;

    @include small {
      position: fixed;
      top: 56px !important;
      left: 0;
      right: 0;
      min-width: 95%;
      max-width: 95%;
      margin: auto;
      transform: initial;
    }

    .popoverArrow {
      z-index: 9;
      position: absolute;
      top: -9px;
      left: 93.3%;
      transform: translateX(-93.3%);
      width: 0;
      height: 0;

      @include small {
        left: 78.5%;
        transform: translateX(-78.5%);
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #cccccc;
      }

      &::after {
        content: "";
        position: absolute;
        top: 1px;
        left: -8px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
      }
    }

    .popoverBody {
      text-wrap: initial !important;
      text-align: left;
    }

    .popoverBody {
      padding: 24px;
      padding-bottom: 0px;
      padding-right: 8px;
      border: 1px solid #cccccc;
      border-radius: 4px;
      background-color: #fff;
      font-size: 12px;
      color: $gray;
      text-wrap: nowrap;

      .notificationBody {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .notificationCardsWrapper {
          overflow-y: auto;
          max-height: 480px;
          padding-right: 3px;

          padding-bottom: 24px;

          box-sizing: border-box;
          scrollbar-gutter: stable;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            margin-bottom: 24px;
            margin-top: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #0000000d;
            border-radius: 4px;
            margin-right: 8px;
          }

          .notificationCards {
            display: flex;
            flex-direction: column;
            gap: 12px;
            overflow-y: auto;

            &:first-child {
              padding-top: 4px;
            }
          }
        }
      }

      .popoverArrowCover {
        z-index: 11;
        position: absolute;
        top: -1px;
        height: 5px;
        background-color: #fff;
        width: 17.5px;
        margin: auto;
        border-radius: 100%;
        right: 25px;

        @include small {
          left: 79.75%;
          transform: translateX(-79.75%);
          right: initial;
        }
      }

      .markAllReadButton {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .notificationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;

        .title {
          font-family: $georgia !important;
          font-size: 21px;
          font-weight: 700;
          line-height: 29.4px;
          text-align: left;
        }

        .action {
          font-family: $nunito;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.1px;
          text-align: left;
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }
        }
      }

      .notificationActions {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-right: 16px;
        gap: 1rem;

        @include small {
          flex-direction: column;
          width: 100%;
          align-items: initial;
        }

        .selectWrapper {
          display: flex;
          flex-direction: column;

          .selectLabel {
            font-family: $nunito;
            font-size: 10px;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: 2px;
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 4px;
            display: block;
          }

          .notificationFilter {
            width: 200px;
            z-index: 999;
            font-size: 14px;

            @include small {
              width: 100%;
            }

            .selectMenu {
              background-color: $white !important;
              left: 14px !important;
              top: 98px !important;
            }
          }
        }
      }

      .notificationsCount {
        font-family: $nunito !important;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 2px;
        text-align: left;
        color: $peach;
        text-transform: uppercase;
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  .noNotificationCard {
    position: relative;
    background-color: $white;
    padding: 24px;
    margin-right: 12px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 600;
  }
}
