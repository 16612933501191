@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.orderTable {
  // margin-top: 30px;
  // margin-bottom: 50px;

  .columnName {
    font-family: $nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    letter-spacing: 0.1em;
    padding: 12px 15px;
    padding-bottom: 15px;
  }

  .productCell {
    display: flex;
    flex-direction: row;
    padding: 0 0 12.5px 15px;
  }

  .productImage {
    width: 32px;
    height: 32px;
    object-fit: scale-down;
    border: 1px solid $light-gray;
    margin-right: 10px;
  }

  .flexContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .productName {
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    color: $gray;
  }

  .productSku {
    font-family: $nunito;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .brandedBox {
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  .brandedBoxContainer {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 0 12.5px 60px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;

    .warningBox {
      display: flex;
      align-items: center;
      align-self: flex-end;
      a {
        display: flex;
        .warningHelpIcon {
          fill: $blue;
          height: 16px;
          width: 16px;
          margin-right: 16px;
        }
      }

      .warningCheckIcon {
        align-self: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .warningText {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.96px;
        text-transform: uppercase;
      }
    }

    .warningSubText {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      padding-top: 6px;
      align-self: flex-end;
    }
  }

  .productQuantity,
  .unitCost {
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    padding-top: 0;
    padding: 0 0 25px 15px;
  }

  .unitCost {
    @include small {
      // display: flex;
      // flex-direction: column;
      align-items: stretch;
    }
  }
}

.divider {
  border-bottom: 1px solid $medium-gray;
  opacity: 0.1;
  margin: 0 15px;
}

.checkBoxWrapper {
  display: flex;
  align-items: center;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.total {
  margin-left: auto;
  padding-right: 15px;

  @include small {
    padding-right: 0px;
  }
}

.adjustPadding {
  padding-bottom: 100px;
}

.adjustAlignment {
  display: flex;
  align-self: flex-start;
}

.checkIcon {
  margin-right: 12px;
  height: 16px;
  width: 16px;
}

.boxContainer {
  padding: 16px;
}

.discount {
  color: $blue;
  font-weight: 700;
  margin: 0px !important;
}

.noDiscount {
  color: $gray;
  font-weight: 400;
  text-decoration: line-through;
  margin: 5px 0 !important;
}

.discountCost {
  color: $gray;
  font-weight: 400;
  text-decoration: line-through;
  margin: 5px 0 !important;
}

.boxDiscountTag {
  position: relative;
  display: flex;
  align-items: end;
  width: 90px;
  // height: fit-content;

  & > * {
    font-family: $nunito;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
  }
  @include small {
    width: 90px;
    left: -16px;
  }
}

.discountWarning {
  position: relative;
  display: flex;
  font-family: $nunito !important;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  color: $blue;
  word-wrap: unset;
  padding: 5px 8px;
  top: 10px;
  right: 0px;
  // margin-bottom: 10px;
  border-radius: 4px;
  // background-color: rgb($color: $blue, $alpha: 0.3);
  background-color: rgb($color: $blue, $alpha: 0.2);
}

.icon {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}

.customerOrderCart {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 0 15px;
  margin: 20px 0;
}

.automaticPaymentStatus {
  display: flex;
  justify-content: center;
  width: 100%;
}
