@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.formDropdownContainer {
  width: 100%;
  margin-bottom: 16px;
}

.dropdownWrapper {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;

  &.error {
    border-color: $error;
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.selectControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  padding: 4px 8px 4px 10px;
  gap: 4px;
  align-self: stretch;
  width: 100%;

  .selectedValue {
    font-family: $nunito !important;
    font-size: 14px;
    font-weight: 400;
    color: $gray;
    overflow: hidden;
    text-transform: capitalize;
  }
}

.dropdownIcon {
  margin-right: 15px;
  color: $gray;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  gap: 8px;
}

.optionItem {
  padding: 8px 12px;
  font-family: $nunito !important;
  font-size: 14px;
  font-weight: 400;
  color: $gray;
  text-transform: capitalize;

  span {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: rgba(230, 136, 128, 0.9);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &:hover {
    background-color: $lighter-gray;

    span::after {
      opacity: 0.5;
    }
  }

  &.selected {
    span::after {
      opacity: 0.5;
    }
  }
}

.errorMessage {
  color: $error;
  font-family: $nunito !important;
  font-size: 12px;
  margin-top: 4px;
  padding-left: 12px;
}
