@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.removeWrapper {
  position: absolute;
  right: -68px;
  top: 36px;

  .removeText {
    text-decoration: underline;
    font-family: $nunito;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }

  .removeText:hover {
    color: $peach;
  }
}

.errorInput {
  :global(.MuiOutlinedInput-input),
  :global(.MuiAutocomplete-input) {
    border-color: $error !important;
    color: $error !important;
  }
}

.acceptMobileTermsWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  position: relative;
  left: -2px;

  p {
    margin: 0;
    font-family: $nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;
  }

  a:hover {
    color: $peach;
    cursor: pointer;
  }
}

.removePhoneModal {
  .title {
    color: $gray;
    font-family: $georgia;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin: 0;
  }

  .description {
    font-family: $nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
    text-align: center;
    margin: 0;
    margin-top: 8px;
  }

  .actions {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.mobileInputWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 110px 1fr;
  align-items: end;

  @include small {
    grid-template-columns: 110px calc(100% - 110px + 12.5px);
    width: 100%;
  }

  :global(.MuiAutocomplete-inputRoot) {
    width: 110px !important;
    position: relative;
    left: 1px;
  }

  .countryInputFocused {
    z-index: 2;
  }

  .countryInputError {
    border-color: blue !important;
  }
}

.mobileInputErrorMessage {
  font-family: $nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  text-align: left;
  margin-top: 4px;
  color: $error;
}

.customInput {
  width: 100%;

  .label {
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 3px;
  }
}
