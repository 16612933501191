@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.root {
  padding: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  margin-bottom: 24px;

  .title {
    font-family: $nunito;
    font-weight: 700;
    font-size: 14px;
    line-height: 19.1px;
    letter-spacing: 0px;
  }

  .description {
    font-family: $nunito;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.1px;
    letter-spacing: 0px;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
}

.alertError,
.alertSuccess {
  font-family: $nunito !important;
  color: #ab0000;
  font-size: 14px;
  background-color: #ab00000d;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.alertSuccess {
  color: $blue;
  background-color: rgba($color: $blue, $alpha: 0.15);
}

.alertWarning {
  background-color: #fff2f0 !important;
  color: $peach;
}
