@import "styles/mixins";
@import "styles/text";
@import "styles/colors";

.noFavoritesView {
  background: $lighter-gray;
  border: $border;
  height: 293px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 1.5rem;

  h2 {
    margin: 0;
    font-family: $georgia;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: $gray;
  }

  p {
    margin: 0;
    font-family: $nunito;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.1px;
    letter-spacing: 0px;
  }
}

.favoritesView {
  .favoritesHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include small {
      padding-top: 16px;
    }
  }

  .favoritesHeaderActive {
    @include small {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
    }
  }

  .favoritesList {
    margin-top: 48px;

    @include small {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  .productListContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    gap: 24px;
    margin-left: 24px;

    @include xlarge {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @include large {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include medium-down {
      grid-template-columns: 1fr 1fr;
    }

    @include small {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 16px;
      margin-top: 1rem;
    }

    @include xsmall {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
