@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.accountAddressesForm {
  .mobileWrapper {
    border-bottom: 1px solid #0000001f;
    padding-bottom: 16px;
  }

  .singleLine {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;

    @include small {
      flex-direction: column;
    }
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    position: relative;
    left: -2px;

    p {
      margin: 0;
      font-family: $nunito;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.37px;
      text-align: left;
    }
  }

  .stackContainer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    gap: 24px;
  }

  .oneGrid {
    display: grid;
    grid-template-columns: 290px 135px 135px;
    gap: 20px;

    @include small {
      display: flex;
      flex-direction: column;
    }
  }

  .twoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include small {
      display: flex;
      flex-direction: column;
    }
  }

  .threeGrid {
    display: grid;
    grid-template-columns: 290px 135px 135px;
    gap: 20px;

    @include small {
      display: flex;
      flex-direction: column;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 165px 165px;
    gap: 20px;

    @include small {
      display: flex;
      flex-direction: column;
    }
  }

  .autoCompleteContainer {
    display: grid;
    grid-template-columns: 290px 135px 135px;
    gap: 20px;

    @include small {
      display: flex;
      flex-direction: column;
    }
  }

  .shippingMethodFormControl {
    border-top: 1px solid #0000001f;
    padding-top: 24px;

    .formLabel {
      font-family: $nunito !important;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
      text-transform: uppercase;
      padding-bottom: 8px;
    }

    .radioGroup {
      display: flex;
      width: 100%;
      background-color: white;
      border: 1px solid #0000001f;

      label {
        width: 100%;
        margin: 0;
      }

      .radio {
        color: $blue;
      }

      .radioWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 8px 16px;

        .radioBox {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .radioText {
          font-family: $nunito !important;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          margin: 0;

          &.priceText {
            min-width: 60px;
            text-align: right;
          }
        }
      }
    }
  }
}

.bold {
  font-weight: 700;
}
