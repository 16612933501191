@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.pageHeader {
  margin-bottom: 5px;
}
.backLink {
  font-family: $nunito;
  font-weight: 600;
  color: $gray;
  font-size: 10px;
  letter-spacing: 2px;
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;

  align-items: center;

  svg {
    margin-right: 5px;
  }

  &:hover {
    color: $peach;
  }

  @include small {
    display: none;
  }
}

.productView {
  @include small {
    padding-bottom: 124px;
  }

  .productViewContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    max-width: 1024px;
    margin: 0 auto;

    margin-top: 20px;
    @include small {
      padding-top: 1rem;
    }

    .productTypes {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 430px;
    }

    .productViewGrid {
      display: grid;
      grid-template-columns: 430px 1fr;
      gap: 56px;

      @include small {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .productImages {
        position: relative;

        .productImgs {
          .thumbs {
            justify-content: flex-start;
            padding-left: 0px;
          }
        }

        img {
          max-height: 350px;
          width: 100%;
          margin: 0 auto;
          height: auto;
          object-fit: contain;
          border-style: none;

          @include small {
            max-height: 300px;
          }
        }
      }

      .productMain {
        .productMainHeader {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          @include small {
            gap: 0.25rem;
          }

          .productName {
            font-family: $nunito;
            font-size: 15px;
            font-weight: 700;
            line-height: 20.46px;
            letter-spacing: 4px !important;
            text-align: left;
            color: $peach;
            text-transform: uppercase;
            margin: 0;
          }

          .productSku {
            display: flex;
            align-items: center;
            gap: 8px;
            font-family: $nunito;
            font-weight: 700;
            color: $medium-gray;
            font-size: 10px;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin: 0;
          }

          .productDescription {
            font-family: $nunito;
            font-weight: 400;
            color: $gray;
            font-size: 12px;
            line-height: 16.37px;
            margin: 0;

            p {
              font-family: $nunito;
              font-weight: 400;
              color: $gray;
              font-size: 12px;
              line-height: 16.37px;
              margin: 0;
            }

            .readMore {
              text-decoration: underline;
              cursor: pointer;
              margin-top: 4px;
              display: block;

              &:hover {
                color: $peach;
              }
            }
          }
        }
      }
    }
  }

  .productVariants {
    margin-bottom: 1.5rem;
  }

  .productVariantNav {
    display: flex;
    gap: 12px;
    align-items: center;

    .productVariantNavTitle {
      font-size: 10px;
      color: $gray;
      line-height: 13.64px;
      font-weight: 600;
      font-family: $nunito;
      letter-spacing: 2px;
    }

    .variantArrow {
      height: 26px;
      width: 26px;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: transparent !important;
      border: none;

      @include small {
        border: 1px solid #0000001f;
      }

      &:hover {
        background-color: #0000001f !important;
      }
    }
  }
}

.productBenefits {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.25rem;

  .productBenefitsIcon {
    width: 24px;
    height: 24px;
    color: red;
  }
}

.productFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .productFooterPrice {
    display: flex;
    flex-direction: column;
  }

  .productFooterActions {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.25rem;

    position: relative;

    .productSrp {
      background-color: #2460711a;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 6px;
    }

    .productProfit {
      background-color: #d56f5b;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 6px;
    }
  }
}

.priceLabel {
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 1px;
  color: #d56f5b;
  font-family: $nunito;
  text-transform: uppercase;
  height: 11px;
}

.dollarSign {
  font-size: 10px;
  color: #d56f5b;
  font-weight: 600;
  position: relative;
  top: -2px;
  font-family: $nunito;
}

.price {
  font-size: 14px;
  color: #d56f5b;
  font-weight: 700;
  line-height: 11px;
  font-family: $nunito;
}

.divider {
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @include small {
    display: none;
  }
}

.productVariantValues {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1rem;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding-bottom: 10px;
  gap: 4px;
}

.productVariantValuesExpanded {
  max-height: 420px !important;
}

.productVariantCollapseControl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  position: relative;

  font-family: $nunito;
  font-weight: 600;
  color: $gray;
  font-size: 10px;
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    color: $gray;
    opacity: 0.8;
  }

  .productVariantCollapseControlShadow {
    position: absolute;
    height: 9px;
    width: 100%;
    top: -12px;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.08) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }
}

.variantBlock {
  padding: 0px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  min-height: 50px;
  max-height: 50px;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: $white;
  text-align: left;
  position: relative;

  &:hover {
    background: #0000000a;
  }

  .variantName {
    font-family: $nunito;
    font-size: 10px;
    font-weight: 700;
    color: $gray;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include small {
      letter-spacing: 1px;
    }
  }
}

.skuLabel {
  display: none;

  font-family: $nunito;
  font-size: 8px;
  font-weight: 600;
  color: $gray;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.variantBlock:hover .skuLabel {
  display: block;
}

.skuLabelActive {
  display: block !important;
}

.skuLabel {
  @include small {
    display: none !important;
  }
}

.variantBlockActive {
  cursor: initial !important;
  background-color: #fff !important;
  border-color: #d56f5b;
  box-shadow: 0px 4px 7px 0px #0000000d !important;
}

.variantFavorite {
  position: absolute;
  right: 4px;
  top: 4px;
}

.variantCircle {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 24px;
  position: relative;

  &.variantCircleOutOfStock {
    &::after {
      content: "";
      position: absolute;
      width: calc(sqrt(2) * 23.5px);
      border-top: 1px solid $gray;
      transform: translate(-5px, 11.5px) rotate(-45deg);
      z-index: 1;
    }
  }
}

.variantSelected {
  position: absolute;
  top: -6px;
  right: -2px;
}

.productActions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 16px;
  margin-bottom: 2rem;
  @include small {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.productInformationContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .productInformationContainerRow {
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    gap: 1rem;

    @include small {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    .productInformationTitle {
      font-family: $georgia;
      font-size: 16px;
      font-weight: 700;
      line-height: 18.18px;
      text-align: left;
      color: $gray;
    }

    .productInformationBody {
      font-family: $nunito;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.37px;
      text-align: left;
      color: $gray;

      ul {
        margin: 0;
        padding-left: 12px;
      }

      p {
        margin: 0;
      }
    }
  }
}

.infoTag {
  padding: 1px 8px 1px 6px;
  gap: 2px;
  border-radius: 100px;
  color: $white;
  background-color: $blue;
  width: fit-content;

  font-family: $nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 13.64px;
  text-align: center;
}

.relatedProductsTitle {
  font-family: $nunito;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.37px;
  letter-spacing: 2px;
  text-align: left;
  color: $gray;
  text-transform: uppercase;

  margin-top: 2rem;
  margin-bottom: 2rem;
}

.relatedProductsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.productBulkInventoryOrders {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  gap: 1rem;

  @include small {
    flex-direction: column;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #cccccc;
  }

  .saveMore {
    font-family: $georgia;
    font-size: 12px;
    font-weight: 700;
    line-height: 13.63px;
    color: $blue;
  }

  .productBulkBlocksContainer {
    display: flex;
    border: 1px solid #0000001f;
    border-radius: 2px;

    .productBulkBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      padding: 8px;
      gap: 0.25rem;

      .quantity {
        font-family: $georgia;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.18px;
        text-align: center;
        color: $blue;
      }

      .units {
        font-family: $nunito;
        font-size: 9px;
        font-weight: 400;
        line-height: 12.28px;
        letter-spacing: 0.2em;
        text-align: center;
        color: $medium-gray;
      }

      .off {
        background: #2460711a;
        padding: 1px 8px;
        border-radius: 100px;
        color: $blue;
        font-family: $nunito;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.01px;
        text-align: center;
      }

      &:nth-child(2) {
        border-left: 1px solid #0000001f;
        border-right: 1px solid #0000001f;
      }
    }
  }
}

.backButton {
  display: none;
  @include small {
    display: block;
    position: absolute;
    left: 0.5rem;
    padding: 0;
    background-color: transparent;
    border: none;

    svg {
      color: #5e5e5e;
      width: 16px;
      cursor: pointer;
    }
  }
}

.productActionsFixed {
  position: fixed;
  bottom: -120px;
  left: 0;
  right: 0;
  background-color: white;
  padding: 16px 10%;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.productActionsFixed.visible {
  bottom: 0;
}

.addedToMyProducts {
  font-family: $nunito;
  font-size: 12px;
  font-weight: 600;
  line-height: 13.64px;
  letter-spacing: 2px;
  text-align: right;
  color: $blue;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.productStatusContainer {
  position: absolute;
  top: 24px;
  left: 40px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.vipContainer {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.premiumTag {
  font-family: $nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 13.64px;
  text-align: center;
  color: $medium-gray;

  border: 1px solid #979797;
  padding: 1px 4px;
  border-radius: 100px;

  margin-bottom: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: fit-content;
}
.orderButton {
  align-self: end;
}

.upgradeText {
  align-self: end;
}

.earlyAccessButton {
  height: 34px;
  font-size: 12px;
  margin-top: 5px;
}

.vipWarning {
  margin-bottom: 1rem;
}

.productNotesText {
  margin-top: 10px;
}

.productSkuRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .heartButton {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent !important;
    border: none;
    border-radius: 2px;
    color: $medium-gray;

    &:hover {
      cursor: pointer;
      color: $peach;
      border: 1px solid #0000001f;
    }
  }

  .favoriteHeartButton {
    &:hover {
      border-color: $blue !important;
    }
  }
}
