@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.popoverContainer {
  position: relative;
  display: inline-block;

  .popoverContentBig {
    min-width: 280px;
    max-width: initial;
  }

  .popoverContent {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    top: -60px;
    max-width: 200px;
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    z-index: 9999;

    .popoverArrow {
      z-index: 9;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;

      // Outer arrow (border)
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -6px; // Adjust to fit the center
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #cccccc; // Outer border color
      }

      // Inner white arrow
      &::after {
        content: "";
        position: absolute;
        top: 1px; // Push down to fit inside the border
        left: -4px; // Adjust to fit inside the border
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid white; // Inner arrow color
      }
    }

    .popoverBody {
      text-wrap: initial !important;
      text-align: left;

      .textOneLine {
        white-space: pre;
      }
    }

    .popoverBody {
      padding: 8px;
      border: 1px solid #cccccc; // Apply the border to the popover content
      border-radius: 4px;
      background-color: #fff;
      font-size: 12px;
      color: $gray;
      text-wrap: nowrap;

      .popoverArrowCover {
        z-index: 11;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: #fff;
        width: 9px;
        margin: auto;
        border-radius: 8px;
      }
    }
  }
}
