@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.accountProfileForm {
  max-width: 600px;
  min-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include small {
    max-width: initial;
    min-width: initial;
  }

  .sideBySide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include small {
      flex-direction: column;
      width: 100%;
      margin-right: 0;
    }

    > div {
      width: 48%;

      @include small {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .emailWrapper {
    position: relative;
    width: 100%;

    .infoButton {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: space-between;
    max-width: 340px;
    // min-width: 315px;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
  }

  .editButton {
    margin-top: 20px;
    max-width: 340px;
    // min-width: 315px;
    width: 100%;
  }

  .label {
    font-family: $nunito !important;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 2px;
    text-align: left;
    padding-top: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .saveCancelButtons {
    max-width: 340px;
    width: 100%;
  }

  .emailField {
    /* Target the input text color */
    .MuiInputBase-input.Mui-disabled {
      color: red !important;
    }

    /* Target the label color */
    .MuiFormLabel-root.Mui-disabled {
      color: red !important;
    }

    /* Target the border color */
    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: red !important;
    }
  }

  .countryField {
  }

  .peach {
    color: $peach;
  }
}
