@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.accountPersonalInformation {
  .backBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: fit-content;
    text-transform: uppercase;

    cursor: pointer;

    font-family: $nunito !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-align: left;
    color: $gray;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .bodyTitle {
    //styleName: Header 1 - Georgia Bold;
    font-family: $georgia !important;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .boxContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    @include small {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .headerDetail {
      font-family: $nunito;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      width: 100%;
      text-align: end;

      @include small {
        padding-bottom: 20px;
      }
    }
  }
}
