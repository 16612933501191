@import "styles/mixins";
@import "styles/text";
@import "styles/colors";

.searchInputContainer {
  position: relative;
  border: 1px solid #cccccc;
  width: 40px;
  max-width: 40px;
  border-radius: 4px;
  min-height: 32px;
  max-height: 32px;
  overflow: visible;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: width 0.3s ease-in-out;
  cursor: pointer;

  &.focused {
    border-color: $peach;
  }

  &.visible {
    width: 400px;
    max-width: 400px;
    border-color: $peach !important;

    @include small {
      width: 100%;
      max-width: 100%;
    }

    &.expanded {
      width: 100%;
      max-width: none;

      .input {
        width: 100%;
      }
    }
  }

  .suggestions {
    position: absolute;
    top: calc(100% + 1px);
    left: -1px;
    right: -1px;
    background: white;
    border: 1px solid $light-gray;
    max-height: 200px;
    width: auto;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .suggestionItem {
      padding: 8px;
      cursor: pointer;
      font-family: $nunito !important;
      font-size: 12px;
      color: $gray;

      &:hover {
        background-color: rgba($peach, 0.1);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($light-gray, 0.1);
      }

      &.selected {
        background-color: rgba($peach, 0.1);
      }
    }
  }
}

.iconContainer {
  min-width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797;
}

.input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px 12px;
  padding-left: 0;
  width: 100%;
  background: transparent;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-right: 1px solid #cccccc;
  pointer-events: none;
  padding-right: 32px;

  @include small {
    padding: 6px 12px !important;
  }

  &.hovered {
    border-color: $peach !important;
  }

  &.focused {
    border-color: $peach !important;
  }
}

.searchInputContainer.visible .input {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.4s ease-in-out 0.1s;
}

.clearIcon {
  position: absolute;
  right: 90px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: $gray;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 14px;
    width: 14px;
  }

  &:hover {
    background-color: $lighter-gray;
  }
}

.searchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 8px 12px;
  background-color: #fff;

  font-family: $nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 13.64px;
  letter-spacing: 2px;
  text-align: center;
  min-height: 32px !important;
  min-width: 84px;
  max-width: 84px;
  cursor: pointer;
  color: $gray;
  border-radius: 0px 4px 4px 0px;

  @include small {
    min-height: auto !important;
  }

  &:hover {
    background-color: $peach;
    color: #fff !important;
    border-radius: 0px 2px 2px 0px;
  }
}
